@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@fontsource/inter/index.css";
@import "~@fontsource/pt-sans";


body {
  font-family: 'Inter', sans-serif;
}

.logo{
  font-family: 'PT Sans', sans-serif;
  font-weight: 700;
  font-size: 20px;
}


.diagonal-background {
    background: linear-gradient(to bottom right, #F9C14A 0%, #F9C14A 50%, #ED5C61 50%, #ED5C61 1000% )
}

.tooltip {
    position: relative;
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 8px;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: 5px;  /* Use half of the width to align it horizontally */
    
    /* Fade in tooltip - optional */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  

  @media (max-width: 768px) {
    .dashboard-container {
      flex-direction: column;
    }
    .sidebar {
      width: 100%; /* Full width on small screens */
      order: -1; /* Optionally move the sidebar to the top on small screens */
    }
    .main-content {
      flex: none; /* Prevents the main content from growing */
      width: 100%; /* Full width on small screens */
    }
  }
  
  
  .databaseModalContent {
    position: relative;
    background-color: #fff;
    top:15%;
    max-height: 100%;
    margin: 0 auto;
    border-radius: 5px;
    font-size: 14px;
    font-style: Regular;
    text-align: left;
    font-family: Open Sans;
    font-weight: 400;
    line-height: 15px;
    font-stretch: normal;
    text-decoration: none;
    overflow: scroll;
  }
  
  .databaseForm input {
    display: block;
    border: 1px solid #E5E7EB;
    border-radius: 5px;
    width: 100%;
    height: 28px;
    padding: 4px;
    margin-bottom: 16px;
  }

  .databaseForm select {
    display: block;
    border: 1px solid #E5E7EB;
    border-radius: 5px;
    width: 100%;
    height: 28px;
    padding: 4px;
    margin-bottom: 16px;
  }
  
  .databaseForm label{
    display: block;
    margin-bottom: 8px;
  }


  
 
  /* Tailwind CSS for Markdown content */
.markdown h1, .markdown h2, .markdown h3 {
  @apply text-xl font-bold mt-4 mb-2;
}

.markdown p {
  @apply mt-2;
}

.markdown ul {
  @apply list-disc list-inside;
}

.markdown a {
  @apply text-blue-600 hover:text-blue-800 visited:text-purple-600;
}

 
  